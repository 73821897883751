
import React, { useState, useContext } from "react"
import 'react-alice-carousel/lib/alice-carousel.css';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { Element } from 'react-scroll'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "gatsby";

import './digital-assistants.css'
import Section from "../shared/Section"
import SEO from "../shared/seo"
import Layout from "../shared/Layout2"
import SectionTitle from '../shared/SectionTitle'
import Container from '../shared/Container'
import NavbarNew from '../shared/NavbarNew'
import LightText from '../shared/LightText'
import MainButton from '../shared/MainButton'
import CustomLink from "../shared/CustomLink";
import { LangugaeContext } from "../../context/LanguageProvider";

const CatItem = (props) => {
    return <Zoom><div className="categoryItem">
        <h2 className={`categoryText ${props.selected ? 'boldText' : ''}`}>{props.category}</h2>
        <button onClick={() => props.onClick()} className={`categoryButton ${props.selected ? 'selectedCategoryButton' : ''}`}>
            <FontAwesomeIcon size="3x" color="white" icon={props.icon} />
        </button>
    </div></Zoom>
}

const CheckIconText = (props) => {
    return <div style={{ display: 'flex' }}>
        <div style={{ marginRight: '10px' }}>
            <LightText fontSize="1.4em" color="var(--lightText)" text="✓" />
        </div>
        <LightText fontSize="1.4em" color="var(--lightText)" text={props.text} />
    </div>
}

const CategoryBlock01 = (props) => {
    return <div style={{
        display: 'flex',
        color: 'var(--titleDA)',
        fontSize: '20px',
        alignItems: 'center',
        width: '100%',
        height: '50px',
        marginBottom: '10px',

    }}>
        ✓ {props.text}
    </div>
}

const CategoryBlockSolid = () => {
    return <div style={{
        display: 'flex',
        justifyContent: 'center',
        color: 'white',
        fontSize: '20px',
        alignItems: 'center',
        width: '100%',
        height: '50px',
        background: '#7d83b3',
        marginBottom: '10px',

    }}>
        ✓ Bokföring
    </div>
}

const SectionWrapper = (props) => {
    return <div
        className={props.clipLeft ? "section-container clip-left" : "section-container clip-right"}
        style={{
            background: props.background ? props.background : '#fff',
            //  clipPath: props.clipLeft ? 'polygon(0 0,100% 15%,100% 100%,0 100%)' : 'polygon(0 15%,100% 0,100% 100%,0 100%)',
        }}>
        <div className="section-wrapper">
            <div className={props.reverseFlex ? "flex-image-and-text reverse-flex" : "flex-image-and-text"} >
                {props.children}
            </div>
        </div>
    </div>
}

const ImageColumn = (props) => {
    return <div className="image-column">
        <img src={props.image} style={{ maxWidth: '500px', width: '100%' }} />
        <div className="category-points-wrapper">
            {props.children}
        </div>
        {/*<CategoryBlockSolid */}
    </div>
}

const TextColumn = (props) => {
    return <div className="text-column">
        {props.children}
    </div>
}

const Title = (props) => {
    return <Fade bottom duration={1000} delay={200} distance="30px">
        <h1 className="sectionTitle" style={{ color: 'var(--titleDA)', fontWeight: 'lighter' }}>{props.text}</h1>
    </Fade>
}

const DigitalAssistantPage = ({cmsData}) => {

    const language = useContext(LangugaeContext);

    return <Layout background="#f7fbfe">
        <NavbarNew />
        <SEO title={"See our digital assistants in diverse areas"}
            description={"Hyr in din digitala assistent idag för att avlasta er från monotona och tidskrävande arbetsuppgifter.Hire your digital assistant today to relieve you of monotonous and time-consuming tasks."}
        />


        <header style={{
            position: 'relative',
            height: '780px',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

        }}>
            <div className="banner"></div>

            <div className="banner-content">
                <div className="banner-text-block">
                    <Fade bottom duration={1000} delay={200} distance="30px">
                        <h1 className="sectionTitle whiteText">{cmsData.title}</h1>
                    </Fade>
                    <LightText fontSize="1.4em" color='#5e638d' resize text={cmsData.text}
                    />
                    <CustomLink to={cmsData.buttonPath}>
                        <button className="create-assistant-button" >
                            <h2 style={{ margin: 0 }}>{cmsData.buttonText}</h2>
                        </button>
                    </CustomLink>
                </div>
                <Fade big>
                    <div className="mascot">
                        <img src={cmsData.mascot.childImageSharp.fluid.src} alt="mascot" />
                    </div>
                </Fade>
            </div>
        </header>

        <div style={{ marginTop: '90px' }}>

            {
                cmsData.categories?.map((category, i) => {
                    return i%2 === 0 ?
                    <SectionWrapper background={category.bg} reverseFlex>
                    <TextColumn>
                        <Title text={category.title} />
                        {category.textList?.map(textBlock => {
                            return <LightText color="var(--lightText)" fontSize="1.2em" resize text={textBlock.text} />
                        })}
                        <hr style={{ borderTop: '2px solid #c3c6d2', width: '50px', marginBottom: '40px' }} />
                        
                        {category.caseTitle != "-" ? <Title text={category.caseTitle} /> : null }
                        
                        {category.caseTextList?.map(textBlock => {
                            return <LightText color="var(--lightText)" fontSize="1.2em" resize text={textBlock.text} />
                        })}
                    </TextColumn>
                    <ImageColumn image={category.image.childImageSharp.fluid.src} >
                        
                    {category.optionalButton.text == "-" ?
                        <React.Fragment>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {category.checkpoints?.map((point, i) => {
                                    return i%2 === 0 ?
                                    <CheckIconText text={point.text} />: null
                                })}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {category.checkpoints?.map((point, i) => {
                                    return i%2 !== 0 ?
                                    <CheckIconText text={point.text} />: null
                                })}
                            </div>
                        </React.Fragment>
                    :
                        <div
                            onClick={() => language.constructPath(category.optionalButton.path, language)}
                            role="button"
                        >
                            <button className="create-assistant-button" >
                                <h2 style={{ margin: 0 }}>{category.optionalButton.text}</h2>
                            </button>
                        </div>
                    }
                    </ImageColumn>
                </SectionWrapper>
                :
                <SectionWrapper background={category.bg} clipLeft>
                <TextColumn>
                    <Title text={category.title} />
                    {category.textList?.map(textBlock => {
                            return <LightText color="var(--lightText)" fontSize="1.2em" resize text={textBlock.text} />
                    })}
                    <hr style={{ borderTop: '2px solid #c3c6d2', width: '50px', marginBottom: '40px' }} />
                    {category.caseTitle != "-" ? <Title text={category.caseTitle} /> : null }
                    {category.caseTextList?.map(textBlock => {
                            return <LightText color="var(--lightText)" fontSize="1.2em" resize text={textBlock.text} />
                    })}
                </TextColumn>
                <ImageColumn image={category.image.childImageSharp.fluid.src} >
                        
                        {category.optionalButton.text == "-" ?

                            <React.Fragment>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    {category.checkpoints?.map((point, i) => {
                                        return i%2 === 0 ?
                                        <CheckIconText text={point.text} />: null
                                    })}
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    {category.checkpoints?.map((point, i) => {
                                        return i%2 !== 0 ?
                                        <CheckIconText text={point.text} />: null
                                    })}
                                </div>
                            </React.Fragment>
                        :
                            <div
                                onClick={() => language.constructPath(category.optionalButton.path, language)}
                                role="button"
                            >
                                <button className="create-assistant-button" >
                                    <h2 style={{ margin: 0 }}>{category.optionalButton.text}</h2>
                                </button>
                            </div>
                        }
                    </ImageColumn>
            </SectionWrapper>
                })
            }

            




            <Element name="firstSection" >
                <Section imageUrl={cmsData.bottom.bg.childImageSharp.fluid.src}>
                    <Container>
                        <SectionTitle color="white" text={cmsData.bottom.title} />
                        <LightText bottom="120" color="white" text={cmsData.bottom.text} />
                        <MainButton tag="contact-button" to={cmsData.bottom.buttonPath} text={cmsData.bottom.buttonText} />
                    </Container>
                </Section>
            </Element>
        </div>
    </Layout >
}

export default DigitalAssistantPage