import React, {useContext} from "react"
import { LangugaeContext } from "../../context/LanguageProvider";

const CustomLink = (props) => {

    const language = useContext(LangugaeContext);

    return(
    <div
        onClick={() => language.constructPath(props.to, language)}
        role="button"
    >
        {props.children}
    </div>
)}

export default CustomLink
