import React from 'react'
import { graphql, StaticQuery } from 'gatsby';
import DigitalAssistantsComponent from '../../../components/digitalassistants/DigitalAssistantsComponent';

function DigitalAssistantsTemplate({ cmsData }) {
  
  const assistantsData = cmsData?.assistants?.edges[0]?.node?.frontmatter ?? {}

  return (
      <div>
          <DigitalAssistantsComponent cmsData={assistantsData}/>
      </div>
  )
}

export default function Home() {
    return (
      <StaticQuery
        query={graphql`
          query {
            assistants: allMarkdownRemark(filter: 
              {frontmatter: {templateKey: {eq: "en-digital-assistants"}}}) {
              edges {
                node {
                  frontmatter {
                    title
                    text
                    mascot {
                        childImageSharp {
                          fluid(maxWidth: 2048, quality: 100) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                    }
                    buttonText
                    buttonPath
                    categories {
                        title
                        textList {
                          text
                        }
                        caseTitle
                        caseTextList {
                          text
                        }
                        image {
                            childImageSharp {
                              fluid(maxWidth: 2048, quality: 100) {
                                ...GatsbyImageSharpFluid
                              }
                            }
                        }
                        bg
                        checkpoints {
                            text
                        }
                        optionalButton {
                          text
                          path
                        }
                    }
                    bottom {
                        bg {
                            childImageSharp {
                              fluid(maxWidth: 2048, quality: 100) {
                                ...GatsbyImageSharpFluid
                              }
                            }
                        }
                        title
                        text
                        buttonText
                        buttonPath
                    }
                  }
                }
              }
            },
          }
        `}
        render={(data) => <DigitalAssistantsTemplate cmsData={data} />}
      />
    );
  }
  